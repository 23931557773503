.cIcon {
  padding: 5px;
}

.cardBtn {
  margin-bottom: 10px;
}

.card {
  margin-top: 20px;
  margin-bottom: 20px;
}