.contactIcon {
  color: black;
}

.cIcon {
  padding: 5px;
}

.cardBtn {
  margin-bottom: 10px;
}

#contactCard {
  margin-bottom: 57.7vh;
}