.card {
  margin-top: 30px;
  margin-bottom: 30px;
}

#leadImg {
  width: 100%;
  max-width: 600px;
}

.sIcon {
  margin: 10px
}