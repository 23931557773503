:root {
  --white: #ffffff;
  --space-grey: #2d3142;
  --silver: #bfc0c0;
  --flight-orange: #f05d23;
  --ultramar-blue: #446df6;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: var(--space-grey) !important;
  background-image: url(./assets/images/aurora.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white) !important;
  font-family: Helvetica, Arial, sans-serif;
}

/* custom utility classes / color palette backgrounds */

.dark {
  background: rgba(45, 49, 66, 0.5) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(45, 49, 66, 0.25) !important;
}



.ultramar {
  background-color: var(--ultramar-blue) !important;
}

.orange {
  background-color: var(--flight-orange) !important;
}

/* text flavors */

.text-ultramar {
  color: var(--ultramar-blue) !important;
}

.text-flight {
  color: var(--flight-orange) !important;
}

.text-silver {
  color: var(--silver) !important;
}

.text-bright {
  color: var(--white) !important;
}

/* button classes */

.btn-flight {
  color: var(--white);
  background-color: var(--flight-orange) !important;
}

.btn-ultra {
  color: var(--white) !important;
  background-color: var(--ultramar-blue) !important;
}

.btn-ultra-glow:hover {
  color: rgba(68, 109, 246, 1);
  box-shadow: 0 5px 15px 
  rgba(68, 109, 246, .4);
}

.footer {
  position: sticky;
  width: 100%;
  height: 65px;
  line-height: 60px;
  overflow: hidden;
}
